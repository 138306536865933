@use "sass:map"
@import 'styles/vars'

.newBadge
  padding: 4px 10px
  border-radius: 4px
  display: inline-flex
  align-items: center
  justify-content: center
  gap: 6px // Adds spacing between the dot and text
  height: 30px

  p
    margin: 0
    font-weight: bold
    text-transform: uppercase

  // Status indicators
  .status-dot
    width: 8px
    height: 8px
    border-radius: 50%
    display: inline-block

  &.in_progress
    background-color: $color-yellow-bg
    color: $color-yellow-text

    .status-dot
      background-color: $color-yellow-text

  &.new
    background-color: $color-blue-bg
    color: $color-blue-text

    .status-dot
      background-color: $color-blue-text

  &.undefined
    background-color: $color-blue-bg
    color: $color-blue-text

    .status-dot
      background-color: $color-blue-text

  &.review
    background-color: $color-review-bg
    color: $color-review-text

    .status-dot
      background-color: $color-review-text

  &.finished
    background-color: $color-finished-bg
    color: $color-finished-text

    .status-dot
      background-color: $color-finished-text
