@import 'styles/vars'
.tab-group
  display: flex
  cursor: pointer
  
.tab-group .tab
  float: left
  font-family: $font-mulish
  font-weight: 700
  font-size: 18px
  line-height: 24px
  letter-spacing: 0%
  color: #222647
  padding: 16px 
  width: 100%
  box-sizing: border-box
  text-align: center
  background: none !important
  background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
  border: 1px solid #01B7FF
  
  span
     color: #fff  
  

.tab-group .active
  background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%) !important

.tab-group .active span
  color: #fff
 


.tab-label
  font-family: $font-mulish
  font-weight: 700
  font-size: 18px
  line-height: 24px
  letter-spacing: 0%


.tab__group
  position: relative
  height: 340px

  &::after
    content: ""
    display: block
    clear: both

.tab__label
  display: inline-block
  padding: 0 20px
  font-size: 16px
  line-height: 40px
  cursor: pointer
  background: #fff

.tab__content
  position: absolute
  width: 100%
  height: 300px
  top: 40px
  left: 0
  right: 0
  padding: 20px
  overflow-x: auto
  background: #fff

.tab__radio
  display: none

  &:not(:checked)
    ~ .tab__label
      background: transparent

    ~ .tab_
