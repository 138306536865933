@use "sass:map"
@import 'styles/vars'
body
  height: 100dvh
  font-family: $font-family
  font-size: 14px
  line-height: 1.2
  font-weight: 400
  color: map.get($text-colors, "slate")
#root
  height: 100%
h1
  margin: 0
  font-size: 56px
h2
  margin: 0
  font-size: 42px
h3
  font-size: 28px
  font-weight: 400
  margin: 0
h4
  margin: 0
  font-size: 18px
  font-weight: 600
  font-family : $font-mulish
h5
  font-size: 20px
  font-weight: 500
  margin: 0
h6
  font-size: 16px
  font-weight: 500
  margin: 0
p
  font-size: 18px
  font-weight: 600
  font-family : $font-mulish
a
  text-decoration: none
  color: $color-theme-primary
::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-placeholder
  opacity: 1 /* Firefox */
  font-weight: 300
:-ms-input-placeholder /* Internet Explorer 10-11 */
  color: $color-placeholder
::-ms-input-placeholder /* Microsoft Edge */
  color: $color-placeholder
.full-page
  width: 100%
  min-height: 100%
.add-staffa-form-container
  height: 100%
  .left
    border-right: 1px solid #B7C5CC
  select
    padding: 12px 8px
    border: 1px solid #D6D9E1
    border-radius: 12px
    font-style: normal
    font-weight: 400
    line-height: 24px
    height: 50px
.half-container
  display: flex
  justify-content: space-between
  .left
    flex-grow: 1
    width: 50%
    padding-right: 24px
  .right
    flex-grow: 1
    width: 50%
    padding-left: 24px
.center
  text-align: center
// External
.ReactModal__Overlay
  display: flex
  align-items: center
  justify-content: center
  z-index: 99
input
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 24px
  padding: 12px
  border: 1px solid rgba(146, 146, 146, 1)
  border-radius: 12px
  width: 100%
  box-sizing: border-box
  &:focus
    border: 1px solid
    border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
  &:focus-visible
    outline: none
  &:active
    border: 1px solid
    border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
select.gray
  background: #EBECF0
  box-sizing: border-box
  padding: 8px 32px 8px 16px
  border: 0
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  display: block
  background-image: url(../assets/select-arrow.svg)
  background-repeat: no-repeat
  background-position: center right 16px
  &:focus-visible
    outline: none
.box-white
  padding: 24px
  border-radius: 3px
  background: #FFF
  box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.10)
// Button Style  
.cbtn
  padding: 10px 16px
  border: 0
  display: flex
  align-items: center
  i
    margin-right: 4px
    svg
      display: block
      margin: 0
  span
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 20px
  letter-spacing: -0.084px
  text-transform: capitalize
  &.cbtn-blue
    background: #00B8D9
    color: #fff
  &.cbtn-redius
    border-radius: 3px
  &.cbtn-center
    margin: 0 auto
  &.cbtn-white 
    background: #fff
    color: #344563
    border: 2px solid #DFE1E6
.text-center
  text-align: center
.hide
  display: none !important
.job-box-cta 
    display: flex
    justify-content: flex-start
    margin-top: 24px
    button.cbtn 
      margin-right: 12px
      cursor: pointer !important
::-webkit-scrollbar
  width: 8px
::-webkit-scrollbar-track
  background: #ebecf0
  border-radius: 10px
::-webkit-scrollbar-thumb
  background: #C1C7D0
  border-radius: 10px
::-webkit-scrollbar-thumb:hover
  background: #555
@mixin common-styles
  padding: 7px 25px
  font-size: 20px
  background: transparent
  font-weight: 500
  height: auto
  max-width: 368px  
  border: 1px solid transparent
  border-image: radial-gradient(circle, rgb(0, 84, 181) 100%, rgb(1, 183, 255) 100%)
  border-image-slice: 1
  border-radius: 4px 4px 4px 4px
.newLight
  @include common-styles
  color: #fff !important
.newDark
  @include common-styles
  color: #fff !important
  background: radial-gradient(circle, rgba(0, 84, 181, 1) 0%, rgba(1, 183, 255, 1) 100%)
.body , .main-container
  background-image: url(../assets/mainBg.png)
  background-repeat: no-repeat
  width:100%
  height: 100%
  background-size: cover
.website-input::placeholder 
  color: #929292 !important
.stepsWrapperDiv
  height: calc(100% - 102px)
  max-width: 1080px
.formWrapperCustomization 
    display: flex
    flex-direction: column
    gap: 24px
    .formRows 
        display: flex
        gap: 24px
        .formsCol 
          width: 100%
          input
            height: 56px !important
            gap: 10px
            border-radius: 6px
            border-width: 1px
            padding: 16px 20px
            border: 1px solid #BFBFBF
            background: none
            font-family: $font-mulish !important
            font-weight: 600
            font-size: 18px
            line-height: 31.63px
            letter-spacing: 0%
            color: #929292
          
          textarea
            height: 120px !important
            gap: 10px
            border-radius: 6px
            border-width: 1px
            padding: 16px 20px
            border: 1px solid #BFBFBF
            background: none
            font-family: $font-mulish !important
            font-weight: 600
            font-size: 18px
            line-height: 31.63px
            letter-spacing: 0%
            color: #929292
.inputAttaced 
  height: 56px !important
  gap: 10px
  border-radius: 6px
  border-width: 1px
  padding: 8px 20px // Add vertical padding
  padding-right: 130px // Ensure enough space for the button inside input
  border: 1px solid #BFBFBF
  background: none
  font-family: $font-mulish !important
  font-weight: 600
  font-size: 18px
  line-height: 31.63px
  letter-spacing: 0%
  color: #929292
  position: relative
  display: flex
  align-items: center
  .browserButton 
    width: 108px
    height: calc(100% - 8px) // Reduce height slightly to create space inside input
    background: #0077FF
    border: 0px
    border-radius: 4px
    position: absolute
    top: 4px // Adds spacing from the top
    bottom: 4px // Adds spacing from the bottom
    right: 4px // Adds spacing from the right
    display: flex
    align-items: center
    justify-content: center
    font-family: $font-inter
    font-weight: 700
    font-size: 18px
    line-height: 19.6px
    letter-spacing: 0%
    text-align: center
    color: #fff
    padding: 0 12px // Adds some spacing inside the button
    input 
      position: absolute
      opacity: 0
      padding: 0 !important
      min-height: auto !important
      cursor: pointer
  
