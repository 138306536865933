@use "sass:map"
@import 'styles/vars'

.navigation
  width: 100%
  box-sizing: border-box
  padding: 0 32px
  display: flex
  flex-direction: column
  justify-content: space-between
  border-right: 1px solid #00020333

  a
    display: inline-flex
    height: 50px
    width: 100%
    padding: 10px 0px
    box-sizing: border-box
    align-items: center
    color: $navbar-gray-color

    &.active
      color: $orange-transparent-color
      background: radial-gradient(50% 388.06% at 50% 50%, $orange-color 0%, $orange-transparent-color 100%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      span
        display: inline-block
        background: radial-gradient(50% 388.06% at 50% 50%, $orange-color 0%, $orange-transparent-color 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        white-space: nowrap
        font-weight: 300

    &:hover
      color: $orange-transparent-color
      background: radial-gradient(50% 388.06% at 50% 50%, $orange-color 0%, $orange-transparent-color 100%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

      span
        display: inline-block
        background: radial-gradient(50% 388.06% at 50% 50%, $orange-color 0%, $orange-transparent-color 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        white-space: nowrap

    img
      opacity: 0.6
      height: 24px
      margin-right: 16px

    span
      font-family: $font-mulish
      font-size: 18px
      font-weight: 600
      line-height: 31.63px
      text-align: left
      text-underline-position: from-font
      text-decoration-skip-ink: none
      color: $navbar-gray-color

  a.active
    position: relative
    text-decoration: none

  a.active::before
    content: ''
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    width: 90%
    height: 1px
    background: radial-gradient(50% 388.06% at 50% 50%, $orange-color 0%, rgba(255, 145, 18, 0) 100%)

  a.active::after
    content: ''
    position: absolute
    top: 100%
    left: 50%
    transform: translateX(-50%)
    width: 90%
    height: 1px
    background: radial-gradient(50% 388.06% at 50% 50%, $orange-color 0%, rgba(255, 145, 18, 0) 100%)

.nav-label
  margin-left: 10px

.navigation-bottom
  margin-bottom: 2.5rem
  color: $navbar-gray-color
  font-family: $font-Onest
  font-size: 18px
  font-weight: 300

.navigation-bottom-items
  display: inline-flex
  height: 50px
  width: 100%
  padding: 10px 0px
  box-sizing: border-box
  align-items: center
  color: $navbar-gray-color
  cursor: pointer

.logout
  color: #C91717
