@import 'styles/vars'
.ant-card-body
  padding: 0 !important

.cardStyles
  &:focus,
  &:hover,
  &.active 
    border-image: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
    border-image-slice: 1
    border: none
    border-radius: 4px !important
    box-shadow: 0 0 0 1px #0054B5, 0 0 0 2px #01B7FF

.TemplateSteps
  font-family: $font-inter
  font-weight: 600
  font-size: 20px
  line-height: 24.2px
  letter-spacing: 0%
  color: #929292
  margin-bottom:16px
  
.header_new 
  display: flex
  align-items: self-start
  margin-bottom:86px
  justify-content: space-between

.Titlesteps
    font-family: $font-inter
    font-weight: 600
    font-size: 30px
    line-height: 42px
    letter-spacing: 0%
    color: #EDEDED

.desTemplate 
    font-family: $font-mulish
    font-weight: 400
    font-size: 18px
    line-height: 31.63px
    letter-spacing: 0%
    color: #BFBFBF
    max-width: 500px

.card_top_heading 
    max-width: 400px



