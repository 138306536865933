@import 'styles/vars'
.manage_billing

    h2
        font-size: 20px
        font-weight: 500
        color: #333
        margin: 0 20px 0 40px
        white-space: nowrap

    p
        color: #444
        font-size: 20px

.manage_billing2
    margin-top: 50px

    h2
        font-size: 30px
        font-weight: 600
        color: #333
        margin-bottom: 10px

    p
        color: #444
        font-size: 20px

.primary_flex
    display: flex
    flex-direction: column
    gap: 15px
    width: 100%

.primary_div
    margin-top: 30px
    display: flex
    align-items: center
    justify-content: space-between

    .primary_col
        display: flex
        justify-content: space-between
        align-items: center
        gap: 40px

        p
            font-size: 22px
            font-weight: 600
            color: #333

.editbtnpay
    font-size: 18px
    font-weight: 600
    color: #2cbbe8
    display: inline-block
    padding-right: 15px

.deletebtnpay
    font-size: 18px
    font-weight: 600
    color: red
    display: inline-block

.pri_par
    color: #444
    font-size: 20px
    margin-top: 20px

.addbilling
    font-size: 24px
    color: #2cbbe8
    font-weight: 500
    margin-top: 30px
    display: inline-block

input[type=text], select, textarea
    width: 100%
    padding: 12px
    border: 1px solid #929292
    border-radius: 4px
    resize: vertical

label
    padding: 12px 12px 12px 0
    display: inline-block

input[type=submit]
    background-color: #04AA6D
    color: white
    padding: 12px 20px
    border: none
    border-radius: 4px
    cursor: pointer

input[type=submit]:hover
    background-color: #45a049

.error-message
    color: red

.col-25
    width: 25%
    margin-top: 6px

.col-75
    float: left
    width: 75%
    margin-top: 6px

.row2
    display: flex
    align-items: center
    gap: 20px

.col-50
    width: 50%
    margin-top: 6px

.col48
    width: 48%

.subbtn
    width: 150px
    margin-top: 50px

.col-100
    width: 100%
    margin-top: 6px

/* Clear floats after the columns */
.row:after
    content: ""
    display: table
    clear: both

.addbilling_method
    border: 1px solid #bbb
    padding: 30px
    h2
        font-size: 30px
        font-weight: 600
        color: #333
        margin-bottom: 10px

button.canclebtn
    background: none
    border: 1px solid #bbb
    padding: 8px 20px 13px 20px
    font-size: 20px
    color: #2cbbe8
    border-radius: 9px
    display: flex
    justify-content: center
    align-items: center
    display: inline-block

.headandbtn
    display: flex
    align-items: center
    justify-content: space-between

.form_add label
    font-size: 18px
    font-weight: 500
    margin-bottom: 5px

.addbilling_box
    display: flex
    align-items: center
    gap: 40px
    margin-top: 60px
    margin-bottom: 40px

    h2
        font-size: 20px
        font-weight: 600
        color: #333
        margin-bottom: 0px

    p
        color: #444
        font-size: 20px
        margin-bottom: 0px
        margin-top: 0px

.form_add
    .row
        margin-left: 0px

    label
        font-size: 18px
        font-weight: 500

::placeholder
    font-size: 20px !important
    color: #4b4a4a !important
    font-weight: 500 !important

.addbilling_box2
    margin-top: 40px
    margin-bottom: 30px

    h2
        font-size: 30px
        font-weight: 600
        color: #333
        margin-bottom: 0px

.cardsdiv
    width: 60px
    height: 40px
    // border: 1px solid #aaa
    // padding: 10px

.cardsbox_wrap
    display: flex
    justify-content: space-between
    align-items: center

.cardsbox
    display: flex
    align-items: center
    gap: 10px

.payicondiv
    display: flex
    align-items: center
    gap: 10px

.udsdivs
    margin-bottom: 20px

.ant-select-selector
    background: #fff !important
    height: 50px !important
    padding: 12px
    border: 1px solid #B7C5CC !important
    border-radius: 4px
    resize: vertical

.udsdivs .ant-select .ant-select-arrow
    height: 30px !important

.udsdiv
    font-size: 20px
    margin-top: 30px

.visaending
    display: flex
    align-items: center
    gap: 15px

.addbillingdiv
    margin-top: 30px
    margin-bottom: 30px
    display: flex
    align-items: center
    gap: 15px

    .addbilling
        margin-top: 0px
/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px)
.col-25, .col-75, input[type=submit]
    width: 100%
    margin-top: 0

.paymentInfoTitle
    font-size: 20px
    font-weight: 600
    font-family: $font-mulish
    font-weight: 600
    font-size: 18px
    line-height: 31.63px
    letter-spacing: 0%

img.imgCards
    width: 40px
    opacity: 0.8

.fieldForm
    padding: 0 15px
    display: flex
    flex-direction: column
    gap: 15px 0

.cardsForm
    width: 100%
    margin-top: 6px
    padding: 0px
    border-radius: 5px
    margin-top: 30px
    display: flex
    flex-direction: column
    gap: 15px 0
    padding-bottom: 15px

    div
        margin-left: 0px !important
        width: 100% !important
        max-width: 100% !important
        gap: 20px

.form_add label
    font-size: 18px
    font-weight: 500
    padding: 0px
    margin: 0px
    margin-top: 0px !important

    .noteLable
        color: #80949D
        display: inline-block

.cardsbox_wrap.manage_billing
    padding: 15px
    border-bottom: 2px solid #00000017

.row.formBtnGroup
    display: flex
    gap: 20px
    margin-top: 60px
    width: 100%
    flex-direction: row-reverse
    padding-right: 2rem

a.formButton
    font-size: 20px
    color: white
    font-weight: 600
    height: 56px
    border-radius: 12px
    background: #00b8d9
    display: flex
    justify-content: center
    align-items: center
    padding: 0 16px
