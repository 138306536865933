
@import 'styles/vars'

.ProductsliderTitle 
    font-family: $font-inter
    font-weight: 600
    font-size: 24px
    line-height: 29.05px
    letter-spacing: 0%
    color: #EDEDED
    margin-bottom: 24px

.ProductsliderDescription 
    font-family: $font-mulish
    font-weight: 600
    font-size: 18px
    line-height: 28.44px
    letter-spacing: 0%
    color: #BFBFBF
    margin-bottom: 24px

.smallCardWrapper 
    display: flex
    gap: 16px 
    flex-wrap: wrap
    justify-content: space-between
  
    .smallCardInfoWrapper 
        width: calc(33% - 10px)
        background: #00020333
        border: 1px solid #00020333
        padding: 8px
        border-radius: 6px
        display: flex
        flex-direction: column
        justify-content: center
        gap: 8px

        &:last-child
              width: 100%
              display: flex
              flex-direction: row
              justify-content: space-between
              padding: 16px

        .InnerCard 
            display: flex
            justify-content: space-between
            gap: 3px
            align-items: center

            p.card__title 
                font-family: $font-mulish
                font-weight: 600
                font-size: 16px
                line-height: 25.28px
                letter-spacing: 0%
                color: #929292
            
            span 
                width: 12px
                height: 12px
                color: #BFBFBF


        .card__content 
            font-family: $font-mulish
            font-weight: 700
            font-size: 20px
            line-height: 24px
            letter-spacing: 0%
            color: #0077FF

.modeFilter 
    margin-top: 48px
    
    p.paraFilter 
        font-family: $font-mulish
        font-weight: 700
        font-size: 18px
        line-height: 28.44px
        letter-spacing: 0%
        color: #EDEDED
        margin-bottom: 8px

    .filterModeButton 
        display: flex
        gap: 16px    

        button 
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            min-height: 57px
            border-radius: 6px
            gap: 18px
            font-family: $font-mulish
            font-weight: 600
            font-size: 16px
            line-height: 25.28px
            letter-spacing: 0%
            color: #FEFEFE
            background: none
            border: 1px solid transparent
            border-image: radial-gradient(circle, rgb(0, 84, 181) 100%, rgb(1, 183, 255) 100%)
            border-image-slice: 2
            border-radius: 4px 4px 4px 4px
            background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            cursor: pointer
            
            &:active
                background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
                border: 1px solid
                border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
            
            &:hover
                background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
                border: 1px solid
                border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
                -webkit-text-fill-color: #fff

                svg
                    filter: brightness(0) invert(1)
        
        .active
                background: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
                border: 1px solid
                border-image-source: radial-gradient(50% 388.06% at 50% 50%, #0054B5 0%, #01B7FF 100%)
                -webkit-text-fill-color: #fff

                svg
                    filter: brightness(0) invert(1)

.appPlatform
    margin-top: 24px