.product-images-slider
  .swiper-slide
    overflow: hidden
    position: relative
    margin-bottom:20px
    
    img
      position: static
      top: 0
      left: 0
      width: 100%

  .swiper-button-prev
    left: 0
    color:#FEFEFE
  
  .swiper-button-prev:after
     font-size: 14px

  .swiper-button-next
    right: 30px
    color: #FEFEFE
 
  .swiper-button-next:after
     font-size: 14px

.product-images-slider-thumbs
  .swiper-slide
    cursor: pointer
    border: 1px solid #dddbdb

    &-thumb-active
      border:2px solid #01B7FF
      border-radius: 2px
   

  .swiper-wrapper
    width: 100%
    overflow: hidden
    position: relative

    img
      position: static
      top: 0
      left: 0
      width: 100%
