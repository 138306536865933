@import 'styles/vars'
$template-bg-color: #f9f9f9
$template-border-color: #ddd
$template-label-color: #1F1F1F
$template-value-color: #1F1F1F
$template-font-size-label: 18px
$template-font-size-value: 18px

.template-section
  margin: 25px 0 0

  .template-name
    margin-bottom: 20px
    color: $template-value-color
    font-family: $font-mulish
    font-weight: 600
    font-size: 18px
    line-height: 31.63px
    letter-spacing: 0%

  .template-details
    .detail-item
      display: flex
      margin-bottom: 15px

      .detail-label
        padding: 0 10px 0 0
        font-size: $template-font-size-label
        color: $template-bg-color
        width: 50%

      .detail-value
        padding: 5px
        color: $template-bg-color
        flex: 1.1
        font-family: $font-mulish
        font-weight: 600
        font-size: 18px
        line-height: 31.63px
        letter-spacing: 0%
        color: #BFBFBF

      .detail-value-image
        padding: 5px
        color: $template-bg-color
        flex: 1.1
        font-family: $font-mulish
        font-weight: 600
        font-size: 18px
        line-height: 31.63px
        letter-spacing: 0%
        color: #BFBFBF
        max-width: 100%
        max-height: 246px
        object-fit: cover
