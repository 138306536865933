@use "sass:map"
@import 'styles/vars'

.sidebar
  width: 300px
  height: 100%
  display: flex
  justify-content: space-between
  flex-direction: column
  flex-shrink: 0
  background: #00020333
  overflow: auto
 

  .top
    display: flex
    flex-grow: 1

  .bottom-sticky
    display: flex
    flex-direction: column

  .made-by-postal
    color: #91BCD4
    font-size: 14px
    display: flex
    align-items: center
    padding: 20px 16px
  


    img
      width: 80%
      padding: 5px

  .create-job
    button
      height: 68px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 0

      img
        width: 24px
        padding: 0
        margin-left: -10px
