@import "styles/vars"

.account-card
    // padding: 15px
    // border: 1px solid #E0E9ED
    // border-radius: 12px
    // box-shadow: 0 10px 20px 5px #0000000d
    // margin-bottom: 20px

    &.active
        border-color: #00B8D9

    .account-card-header
        display: flex
        align-items: center
        justify-content: flex-end
        gap: 15px

        .account-card-status
            padding: 5px 10px
            font-size: 16px
            border-radius: 8px
            font-weight: 500

            &.active
                background: #E0FFDF
                color: #178814

            &.expired
                background: #FFD3D3
                color: #A13030

    .account-card-body
        display: flex
        gap: 24px

        .account-card-headings
            border: 0.5px solid #929292
            width: 86%
            height: 65px
            display: flex
            align-items: center
            justify-content: space-between
            padding: 0 20px

            .account-card-details
                display: flex
                align-items: center
                gap: 10px

            .account-card-title
                font-family: $font-poppins
                font-weight: 400
                font-size: 14px
                line-height: 21px
                letter-spacing: 0%

            .account-card-expiry
                font-family: $font-poppins
                font-weight: 400
                font-size: 16px
                line-height: 24px
                letter-spacing: 0%

    span.CardNumber
        display: inline-block
        max-width: 250px
        width: 100%
        font-size: 22px
        color: #000

.button_payment.button_remove
    background: #00b8d9
    border: 0
    padding: 17px 24px
    color: white
    font-weight: 600
    border-radius: 12px
    font-size: 18px
    text-transform: capitalize
    display: inline-block
    line-height: normal
    box-sizing: border-box
    margin-top: 30px

.button-delete
    display: flex
    align-items: center
    font-family: $font-mulish
    font-weight: 700
    font-size: 18px
    line-height: 24px
    letter-spacing: 0%

    img
        width: 25px
        height: 23px
