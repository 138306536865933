@use "sass:map"
@import 'styles/vars'
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap')
@import url('https://fonts.cdnfonts.com/css/onest')
.container
  height: 100%

  .body
    display: flex
    height:100%

    .detail
      width: 100%
      height:100vh
      overflow: auto
      position: relative
      color: white

      .main-container
          padding: 32px 32px 0
          height: calc(100% - 96px)

      .main-container> div
          width:100%
         

           

input
  font-family: "Barlow Semi Condensed", sans-serif!important
  font-size: 18px!important
  height: 50px!important
  border-color: #B7C5CC!important 

  &:disabled
    cursor: not-allowed

  &:not(:disabled):hover
    border-color: #00B8D9!important

  &:focus,&:active
    border-color: #00B8D9!important


textarea
  font-family: "Barlow Semi Condensed", sans-serif!important
  font-size: 18px!important
  border: 1px solid #B7C5CC!important 
  padding: 12px
  border-radius: 12px

  &:not(:disabled):hover
    border-color: #00B8D9!important

  &:disabled
    cursor: not-allowed
  
  &:focus,&:active
    border-color: #00B8D9!important

.jobs-tabs-bar
  display: flex
  align-items: center
  gap: 10px
  position: relative

.pri_btn 
  border: none !important
  border-radius: 0px !important
  background: none !important
  color: #265068 !important
  font-weight: 400 !important
  font-size: 20px !important
  font-family: 'Barlow Semi Condensed'
  position: relative

  &.primary_active
    color: #00314D !important
    font-weight: 500 !important
  

.jobs-tabs-bar:before 
  content: ''
  width: 100%
  height: 5px
  position: absolute
  top: 55px
  background: #E0E9ED
  transform: skewX(-40deg)

button.primary.button.primary_active:before 
  width: 100%
  height: 5px
  position: absolute
  background: #00B8D9
  top: 55px
  content: ''
  transform: skewX(-40deg)




.sort-title
  color: #265068
  font-size: 18px
  font-weight: 500
  margin-top: 1px

.custom-dropdown-main 
  .ant-select-selector
    background-color: #F0F6F8!important
    border: none!important
    border-radius: 12px!important
  
  .ant-select-selection-item
    font-weight: 600
    color: #265068

.custom-dropdown .ant-select-item
  font-size: 18px!important
  color: #60747E!important
  font-weight: 600!important
  
  &.ant-select-item-option:hover 
    background-color: #E1FAFF!important
    color: #00B8D9!important
  
  &.ant-select-item-option-active
    background-color: transparent!important

  &.ant-select-item-option-selected 
    background-color: #E1FAFF!important
    color: #00B8D9!important


.success-main-container
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%

.success-container_
  max-width: 468px
  width: 100%
  padding: 48px 24px
  border-radius: 12px
  border: 1px solid #EDEDED
  text-align: center

  .icon-contaoner
    width: 188px
    height: 188px
    display: flex
    align-items: center
    justify-content: center
    background: rgba(34, 35, 57, 0.05)
    border-radius: 50%
    margin: auto
    margin-bottom: 48px
  
  h3
    font-size: 40px
    font-weight: 600
    color: #00314D
    margin-bottom: 24px
  p
    font-size: 18px
    font-weight: 400
    color: #60747E
    margin-bottom: 24px

  &.error
    h3
      color: #EB5757

