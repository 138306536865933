.add-card-button
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
    
.payments-main-container
    display: flex
    height: 100%

    .payments-left-side
        width: 30%
        min-width: 400px
        flex-shrink: 0
        border-right: 1px solid #B7C5CC
    
    .payments-right-side
        width:70%

        .cards-list
            display: flex
            flex-wrap: wrap

            > *
                width: calc(50% - 20px)
                max-width: 450px
                margin-left: 20px

.base-modal 
    
    .close-button 
        
        img
             width: auto
             height: auto

// Define loader styles
.loader
    border: 4px solid #f3f3f3 // Light bluish color
    border-top: 4px solid #3498db // Dark bluish color
    border-radius: 50%
    width: 30px
    height: 30px
    animation: spin 1s linear infinite // Apply rotation animation
    position: absolute
    top: 50%
    left: 50%
    margin-top: -15px // Half of the loader height
    margin-left: -15px // Half of the loader width


@keyframes spin
    0% 
        transform: rotate(0deg)
    100% 
        transform: rotate(360deg)

section.paymentMain 

    h1.paymentTitle 
        font-size: 24px
        color: #2cbbe8
        font-weight: 600
        border-bottom: 1px solid #80808054
        padding-bottom: 20px

    .PymentInfoDiv 
        display: flex
        justify-content: start
        margin: 24px 0
        align-items: center
        gap: 100px

    p.paymentInfoTitle 
        font-size: 20px
        font-weight: 600

    p.paymentInfopara 
        font-size: 18px
        font-weight: 500
        color: #444
    
    a.button_payment 
        background: #00b8d9
        border: 0
        padding: 17px 24px
        color: white
        font-weight: 600
        border-radius: 12px
        font-size: 18px
        text-transform: capitalize
        display: inline-block
        line-height: normal
        box-sizing: border-box


